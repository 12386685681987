import React from 'react';
import { SelectField } from '../SelectField';
import { MenuItemField } from '../MenuItemField';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { getLocations } from '../../../redux/states/statesSelectors';

export const LocationSelectField = ({
  id,
  label,
  name,
  helperText,
  ...restProps
}) => {
  const locationsState = useSelector(getLocations);
  return (
    <Field
      as={SelectField}
      id={id}
      label={label}
      name={name}
      variant="standard"
      helperText={helperText}
      {...restProps}
    >
      {locationsState?.length ? (
        locationsState.map((location) => (
          <MenuItemField
            key={location.term_id}
            id={location.term_id}
            value={location.term_id}
          >
            {location.name}
          </MenuItemField>
        ))
      ) : (
        <MenuItemField disabled value={0}>
          Loading...
        </MenuItemField>
      )}
    </Field>
  );
};
