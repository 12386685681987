import axios from 'axios';

export async function fetchLocation() {
  try {
    const response = await axios.get('/events/get-states.php?state=events');
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
}
